













































import { defineComponent, computed, ref, PropType } from '@nuxtjs/composition-api';
import { useImage, useProduct } from '~/composables';
import SfPrice from '../../molecules/Product/SfPrice.vue';
import JetUiButton from '../../atoms/JetUiButton/JetUiButton.vue';
import productGetters, { getSlug } from '~/modules/catalog/product/getters/productGetters';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import { SfIcon } from '@storefront-ui/vue';
export default defineComponent({
  name: 'ResultCard',
  components: {
    SfPrice,
    
  },
  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getMagentoImage } = useImage();
    const { getProductPath } = useProduct();
    const qty = ref(1);

    const productImage = computed(() =>
      getMagentoImage(productGetters.getProductThumbnailImage(props.product))
    );
    const productPrice = productGetters.getPrice(props.product);
    const productName = computed(() => productGetters.getName(props.product));
    const productLink = computed(() => productGetters.getSlug(props.product));

    const addToCart = () => {
      emit('add-to-cart', { product: props.product, quantity: qty.value });
    };

    const increaseQty = () => {
      qty.value++;
    };

    const decreaseQty = () => {
      if (qty.value > 1) {
        qty.value--;
      }
    };

    const validateQty = () => {
      if (qty.value < 1) {
        qty.value = 1;
      }
    };

    const isInStock = computed(() => props.product.stock_status === ProductStockStatus.InStock);

    return {
      productImage,
      productName,
      qty,
      productGetters,
      addToCart,
      increaseQty,
      decreaseQty,
      productLink,
      validateQty,
      productPrice,
      isInStock,
    };
  },
});
