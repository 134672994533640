































































































import {
  ref, defineComponent, computed, onMounted, inject, Ref
} from '@nuxtjs/composition-api';
import {
  SfMegaMenu, SfScrollable, SfMenuItem, SfButton,
} from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import ResultCard from '~/components/JetUi/organisms/searchResults/ResultCard.vue';
import { useProduct, useCart } from '~/composables';

export default defineComponent({
  name: 'SearchResults',
  components: {
    ResultCard,
    SfMegaMenu,
    SfScrollable,
    SfMenuItem,
    SfButton,
    SvgImage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: String,
    },
  },

  setup(props) {
    const term = ref('');
    const { getProductList } = useProduct();
    const { updateSearchTerms, productSearchResults, loading, searchTerms } = inject<{ updateSearchTerms: (term: any) => void; productSearchResults: Ref<any>; loading: Ref<boolean>; searchTerms: Ref<string> }>('searchTerms');
    const { addItem } = useCart();
    const featuredProducts = ref(null);

    const handleAddToCart = ({ product, quantity }) => {
      addItem({ product, quantity });
    };

    const searchUrl = computed(() => `/search/${searchTerms.value}`);

    const mostSearchedTerms = [
      'papel',
      'divisória',
      'teclado',
      'mouse',
      'pincel',
      'caderno',
    ];

    const isMobile = window.innerWidth < 1024;

    const getFeaturedProds = async () => {
      const searchParams = {
        filter: {
          sku: {
            in: ['720026', '720032', '720012', '720001', '220010', '720044'],
          },
        },
        pageSize: 10,
      };

      try {
        const result = await getProductList(searchParams);
        featuredProducts.value = result;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      if (!productSearchResults.value || productSearchResults.value.length === 0) {
        await getFeaturedProds();
      }
    });

    return {
      term,
      searchUrl,
      mostSearchedTerms,
      featuredProducts,
      handleAddToCart,
      isMobile,
      updateSearchTerms,
      productSearchResults,
      loading,
      searchTerms,
    };
  },
});

